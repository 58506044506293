@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
   --first-color: #0e2431;
   --second-color: #1d1d1d;
   --third-color: #888787;

   --hover-color: #474747;

   --body-bg-color: #fefefe;
   --card-bg-color: #fff;
   --modal-bg-color:#fff;
   --bg-transparent-color: rgba(0,0,0,0.1);
   --transparent-color-01: rgba(197, 196, 201, 0.1);
   --transparent-color-02: rgba(103, 103, 105, 0.1);
   --line-color: #d7d7d7;

   --color-filter: invert(1);
   --box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);

   --small-font-size: 0.9em;
   --normal-font-size: 1em;

   --scroll-bar-color: #c5cadf;
   --scroll-thumb-color: #70768a;
   --scroll-thumb-hover-color: #454f6b

}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

::-webkit-scrollbar{
  width: 10px;
  background: var(--scroll-bar-color);
}

::-webkit-scrollbar-thumb{
  background: var(--scroll-thumb-color);
  border-radius: 2em;
}

::-webkit-scrollbar-thumb:hover{
  background: var(--scroll-thumb-hover-color);
  border-radius: 2em;
}

html{
  scroll-behavior: smooth;
}

body{
  color: var(--first-color);
  background: var(--body-bg-color);
  margin: 2rem 0 0 0;
  transition: 0.5s ease;

}

a{
  text-decoration: none;
}

li{
  list-style: none;
}

.scrollerc {
  max-width: 1100px;
 
}
.home-img img {
  -webkit-mask: linear-gradient(
    to bottom,
    red 0%,         /* Top of the image is fully visible */
    red 80%,        /* Bottom starts fading */
    transparent 100% /* Fully transparent at the very bottom */
  ); 
  mask: linear-gradient(
    to bottom,
    red 0%,         
    red 80%,        
    transparent 100% 
  ); 
  -webkit-mask-composite: destination-in;
  mask-composite: intersect; /* Ensures the mask combines properly */
}


.scrollerc__inner {

  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  height: 200px;
}
.scrollerc {
  overflow: hidden;
  padding-top: 20px;
  -webkit-mask: linear-gradient(
    90deg,
    transparent, 
    red 20%, 
    red 80%, 
    transparent
  ); 
  mask: linear-gradient(
    90deg,
    transparent, 
    red 20%, 
    red 80%, 
    transparent
  );
}

 .scrollerc .scrollerc__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 70s) linear infinite !important;
 }

 .scrollerc .scrollerc__inner img{
  height: 130px;

 }

 @keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem))
  }
}
/* header */
header{
  z-index: 999999;
  width:100%;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(20px);
  transition: 0.71s ease;

}

header.sticky{
  background: rgba(255, 255, 255, 0.1);
  box-shadow: var(--box-shadow) !important;

}

.nav-bar{
  position: relative;
  height: calc(4rem + 1rem);
  max-width: 1250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
  transition: 0.71s ease;
}

header.sticky .nav-bar{
  height: calc(2.5rem + 1rem);
}

.nav-menu-btn{
  display: none;

}

.nav-bar .logo{
  color: var(--first-color);
  font-size: 1.3em;
  font-weight: 600;

}

.nav-items{
  color: var(--first-color);
  font-size: var(--normal-font-size);
  font-weight: 500;
}

.nav-items a{
  color: var(--first-color);
  font-size: var(--normal-font-size);
  font-weight: 400;
}
.nav-items a:not(:last-child){
  margin-right: 50px;
}

.nav-items a:hover{
  color: var(--second-color);
  
}

.home {
  position: relative;
  max-width: 1250px;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  padding: 4rem 2rem ;
  padding-bottom: 0;
  flex-direction: column;

}



.home .home-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

}

.swiper-container {
  width: 100%;
  height: 20px; 
  padding-top: 2rem;
}

/* Swiper Slide Styling */
.mySwiper img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image scales appropriately */
}


.home-container .media-icons{
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}

.home-container .media-icons a{
  color: var(--second-color);
  font-size: 1.5em;
  margin: 10px 0;
}

.home-container .media-icons a:hover{
  color: var(--hover-color)

}

.home-container .info h2{
  font-size: 4em;
  font-weight: 600;
  line-height: 70px;
}

.home-container .info h3{
  color: var(--third-color);
  font-feature-settings: 1.3em;
  font-weight: 600;
  line-height: 50px;

}

.home-container .info p{
  color: var(--third-color) !important;
  font-size: var(--normal-font-size);
  max-width: 350px;
}


.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  background: var(--second-color);
  color: #ffffff;
  font-size: var(--normal-font-size);
  font-weight: 400;
  display: inline-block;
  margin-top: 25px;
  padding: 15px 25px;
  letter-spacing: 1px;
  border-radius: 15px;
}

.btn:hover {
  background: var(--hover-color);
}
.section {
  position: relative;
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
  padding: 6rem 2rem 2rem;
}



.sub-section{
  position: relative;
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
  padding: 6rem 0;
}


.section-title-01{
  font-size: 2.5em;
  font-weight: 700;
  transform: translateY(-80px);
}


.container {
  position: relative;
  flex-direction: column;
}



.about-section {
  padding-top: 3.5rem;
}

.description-section-02{
  margin-top: 20px;
}
.about .container .content {
  column-gap: 40px;
  width: 100%;
}
.about-img{
  position: relative;
  position: relative;
  clip-path: polygon(0 0, 85% 0, 100% 20%, 100% 100%, 0 100%);
  transition: transform 0.1s ease-out;
  overflow: hidden;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);

}




.about-img img{
  max-width: 100%;
  min-width: 500px;
  border-radius: 10px;
  object-fit: cover;

}
.about-info .description{
  max-width: 600px;

}

.about-info .description h3{
  font-size: 1.3em;
  margin-bottom: 10px;
}

.about-info .description p{
  font-size: var(--normal-font-size);
  color: var(--third-color);

}

.cards {
  padding: 60px 0;
}

.cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
  gap: 20px;
  width: 100%;
}

.card {
  background-color: var(--card-bg-color);
  border-bottom: 3px solid var(--second-color);
  border-radius: 6px;
  box-shadow: var(--box-shadow);
  transition: .3 ease;
}


.card-image-container {
  overflow: hidden;
}

.card-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: transform 0.3s ease;
}


.card-content {
  padding: 20px;
  text-align: left;
}

.card-title {
  font-size: 1.5rem;
  line-height: 30px;
  font-weight: bold;
  color: var(--color-heading);
  margin: 0 0 10px 0;
}

.card-description {
  font-size: var(--small-font-size);
  line-height: 30px;
  margin: 10px 0 20px 0;
  font-weight: 500;
}

.project-badges {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.badge, .badge-red {
  background-color: #373737;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9rem;
  white-space: nowrap;
}

.badge-red{
  background-color: rgb(201, 90, 90)
}


.skills .container .content{
  width: 100%;
}

.skills-description{
  max-width: 700px;
  margin-bottom: 50px;

}

.skills-description h3{
  font-size: 2em;
  margin-bottom: 5px;

}


.skills-info{
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
  margin: 0 auto;
}

.skills-info h4{
  margin-bottom: 20px;

}

.skills-info h4 label {
  background: var(--second-color);
  color: #fff;
  font-size: var(--normal-font-size);
  font-weight: 400;
  padding: 5px 15px;
  border-radius: 5px;
}

.skills-all {
  margin-bottom: 80px;
}

.bar{
  background: var(--card-bg-color);
  box-shadow: var(--box-shadow);
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 6px;
  transition: .3s ease;

}

.bar .info{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  font-size: var(--small-font-size);

}

.bar .info span{
  font-weight: 600;
}

.bar .line{
  position: relative;
  width: 100%;
  height: 7px;
  background: #c4c4c4;
  border-radius: 2px;
}

.bar .line::before{
  content: '';
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--second-color);
  border-radius: 2px;
  width: var(--progress-width);
  
}


.contact .container .content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.content-left {
  flex: 2; /* Takes up a smaller portion */
  max-width: 40%; /* Optional, limits the width */
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}



.content-left iframe{
  border-radius: 8px;
  display: block;
}

.contact-right {
  flex: 2; /* Takes up a larger portion */
  max-width: 54%;
}

.contact-right p, .content-left p{
  color: var(--third-color);
  font-size: 1.18em;
  margin-bottom: 30px;


}


.contact-form input, .contact-form textarea{
  border: none;
  color: var(--first-color);
  background: var(--transparent-color-02);
  font-size: var(--normal-font-size);
  margin-bottom: 20px;
  padding: 15px 40px 40px 20px;
  border-radius: 15px;
   
}

.input-left input{
  border: none;
  color: var(--first-color);
  background: var(--transparent-color-02);
  font-size: var(--normal-font-size);
  margin-bottom: 20px;
  padding: 15px 40px 40px 20px;
  border-radius: 10px;
  width: 100%;
  margin-top: 10px;
  height: 70px;
}


.contact-form textarea{
  width: 100%;
  resize: none;


}
::placeholder{
  color: var(--first-color);

}

.first-row input{
  width: 100%;
  height: 70px;
  
}



.contact-form .second-row{
  display: flex;
  justify-content: space-between;
  height: 90px;
}

.contact-form .second-row input{
  width: 48%;

}


.contact-form .btn{
  border: none;
  margin-top: 0;

  cursor: pointer;
}

@media screen and (max-width:730px){
  body {
    margin: 5rem 0 0 0 
  }

  .section-title-01 {
    font-size: 3rem;
  }

  .home .home-container {
    display: grid;

  }

  .home .home-img{
    position: absolute;
  }

  .home-container .home-img img {
    width: 65%;
    transform: translate(80px, -120px) !important;

  }

  .home .home-container .info { 
    font-size: 0.8rem;
    text-align: center;

  }

  .media-icons { 
    margin-bottom: 80px;
  }
}

@media screen and (max-width:1070px) {
  .navigation{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    background: var(--transparent-color-01);
    visibility: hidden;
    opacity: 0;
    transition: .3 ease;
  }


  .navigation.active{
    visibility: visible;
    opacity: 1;

  }
  

  .navigation.active .nav-items{
    transform: translateY(0);
  }

  .nav-close-btn{
    background: 30px no-repeat url('/public/images/close.svg');
    background-size: 30px;
    background-position: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: block;
    
  }

  .nav-menu-btn{
    background: 30px no-repeat url('/public/images/burger.svg');
    background-size: 30px;
    background-position: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: block;

  }

  .nav-items{
    position: relative;
    background-color: var(--modal-bg-color);
    width: 600px;
    max-width: 600px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    transform: translateY(-50px);
    transition: .3 ease;
  }

  .nav-items a{
    margin: 15px 50px;
  }

  .home .home-container .info{
    font-size: 0.80rem;
  }

  /* SWIPER HERE */

  .about .container .content{
    display: grid !important;
    width: 100% !important;
    row-gap: 20px !important;
  }

  .about-img img{
    min-width: 0;
    width: 100%;
  }

  .about-info{
    min-width: 0;
    width: 100%;
  }
  


  
}
